export default function () {
  return [
    { label: 'Asset', value: 'as' },
    { label: 'Liability', value: 'la' },
    { label: 'Equity', value: 'eq' },
    { label: 'Revenue', value: 're' },
    { label: 'Expense', value: 'ex' },
    { label: 'Human Asset', value: 'hu' },
    { label: 'Lawsuit', value: 'lw' }
  ]
}
